<template>
  <!-- 单点登录在线考试 -->
  <div class="external_view_FinishPaper">
    <div v-if="loading">
      <van-loading type="spinner"
                   color="#1989fa"
                   vertical>加载中...</van-loading>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getSsoPaperInfo } from "@api/wxzp.js";
// exitPrompt
export default {
  data () {
    let guid = this.$route.query.guid || 'e70befef1d3b423fbada4c745855bb93'
    return {
      guid,
      mautoid: 0,
      empid: 0,
      username: '',
      teststyle: '',
      paperid: '',
      loading: false,
    }
  },
  created () {
    this.loading = true
    this.getSsoPaperInfoData()
  },
  methods: {
    // 获取问卷的单点登录数据
    getSsoPaperInfoData () {
      getSsoPaperInfo({
        guid: this.guid
      }).then(res => {
        if (res.data.length > 0)        {
          this.loading = false
          this.empid = res.data[0].empid
          this.teststyle = res.data[0].teststyle
          this.paperid = res.data[0].paperid
          this.mautoid = res.data[0].mautoid
          this.username = res.data[0].username
          this.$router.push({
            path: "/examinInfo",
            query: {
              paperid: this.paperid,
              mautoid: this.mautoid,
              teststyle: this.teststyle,
              empid: this.empid,
              username: this.username,
              from: 'dandian',
              guid: this.$route.query.guid
            },
          });
        } else        {
          Toast.fail('guid参数无效或已过期！');
        }
      })
    },
  }

};
</script>
 
<style lang="less" scoped>
.external_view_FinishPaper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>